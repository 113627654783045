<template>
  <div class="container-xl d-flex flex-column justify-content-center">
    <div class="col-lg-9">
                <div class="card card-lg">
                  <div class="card-body">
                    <div class="markdown">
                      <div>
                        <div class="d-flex mb-3">
                          <h1 class="m-0">Changelog</h1>
                        </div>
                      </div>
                      <div v-for="log in ChangeList" :key="log.id" class="mb-4">
                        <div class="mb-2">
                          <span class="badge bg-blue-lt">{{ log.build }}</span> –
                          <span class="text-muted">{{ log.date }}</span>
                        </div>
                        <ul>
                          <li v-for="(desc, index) in log.description.split('\n')" :key="index">{{ desc }}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "ChangelogPage",
    data: function () {
      return {
        
      }
    },
    methods: {
      ...mapActions("changeList", ["LoadChangeData"]),
    },
    computed: {
        ...mapState("changeList", ["ChangeList"]),
    },
    created() {
      this.LoadChangeData()
    }
}
</script>